<div class="title-button">
  <div class="title">
    {{ title }} (<span>{{ counter }}</span
    >)
  </div>
  @if (canShowMore) {
    <button
      mat-stroked-button
      aria-label="Close button"
      (click)="clickShow()"
    >
      <mat-icon
        class="icons material-icons-outlined"
        [fontIcon]="isAllVisible ? 'visibility_off' : 'visibility'"
      ></mat-icon>
      {{ isAllVisible ? 'Show less' : 'Show more' }}
    </button>
  }
</div>
<ng-content select="[content]"></ng-content>
