import { CountModel, DimensionsEnum } from '@dmc-ng/data-access/countings';
import { saveAs } from 'file-saver';
import { isArray } from 'lodash';
import { unparse } from 'papaparse';

import { GeographicalShareEnum } from '../models/enum/geographical-share.enum';

export class ExportShareUtils {
  static exportGeographicalData(
    geoData:
      | string[]
      | {
          lat: number;
          lng: number;
          radius: number;
        }[]
      | {
          radius: number;
          entries: {
            code: string;
            name: string;
            lat: number;
            lng: number;
          }[];
        },
    type: GeographicalShareEnum,
    versionName: string,
  ): void {
    let csvData = '';
    const csvLines: string[] = [];

    switch (type) {
      case GeographicalShareEnum.City:
        if (geoData.entries && isArray(geoData.entries)) {
          csvLines.push('insee_code,label');
          (
            geoData.entries as {
              code: string;
              name: string;
              lat: number;
              lng: number;
            }[]
          ).forEach((city) => {
            csvLines.push(`${city.code},${city.name}`);
          });
        }
        break;

      case GeographicalShareEnum.Department:
        if (isArray(geoData)) {
          csvLines.push('department_number');
          (geoData as string[]).forEach((item) => {
            csvLines.push(`${item}`);
          });
        }
        break;

      case GeographicalShareEnum.District:
        if (isArray(geoData)) {
          csvLines.push('insee_code');
          (geoData as string[]).forEach((item) => {
            csvLines.push(`${item}`);
          });
        }
        break;

      case GeographicalShareEnum.Zip:
        if (isArray(geoData)) {
          csvLines.push('zip_code');
          (geoData as string[]).forEach((item) => {
            csvLines.push(`${item}`);
          });
        }
        break;

      case GeographicalShareEnum.Iris:
        if (isArray(geoData)) {
          csvLines.push('iris_code');
          (geoData as string[]).forEach((item) => {
            csvLines.push(`${item}`);
          });
        }
        break;

      case GeographicalShareEnum.Coordinates:
        if (isArray(geoData)) {
          csvLines.push('latitude,longitude,radius');
          (geoData as { lat: number; lng: number; radius: number }[]).forEach(
            (poi) => {
              if (poi.lat && poi.lng && poi.radius) {
                csvLines.push(`${poi.lat},${poi.lng},${poi.radius / 1000}`);
              }
            },
          );
        }
        break;
    }

    csvData = csvLines.join('\n');

    const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
    saveAs(blob, `${versionName}_${type}_export.csv`);
  }

  static exportCountsDetailsByDimensionsData(
    versionName: string,
    columns: DimensionsEnum[],
    counts: CountModel[],
  ): void {
    const headers = [...columns.map((col) => col.toString()), 'count'];

    const data = counts.map((countObj) => {
      const { dimensions, count } = countObj;

      const line = columns.map((column) => {
        const value = dimensions[column];
        return value !== undefined ? value.toString() : '';
      });

      line.push(count.toString());

      return line;
    });

    const csvData = unparse({
      fields: headers,
      data: data,
    });

    const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
    saveAs(blob, `${versionName}_by_dimensions_export.csv`);
  }
}
