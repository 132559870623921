import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'labelFromId',
  standalone: true,
})
export class LabelFromIdPipe implements PipeTransform {
  transform(
    items: { id: string; label: string; placeId?: string }[] | null,
    targetValue: string,
    concat = false,
    withPlaceId = false,
  ): string | undefined {
    const foundItem = items?.find((item) => item.id === targetValue);
    if (!foundItem) {
      return undefined;
    }
    if (concat) {
      if (withPlaceId && foundItem.placeId) {
        return `${foundItem.label} (${foundItem.placeId})`;
      }
      return `${foundItem.id} - ${foundItem.label}`;
    } else {
      return foundItem.label;
    }
  }
}
