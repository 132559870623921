import { createFeatureSelector, createSelector } from '@ngrx/store';

import { PUBLIC_FEATURE_KEY, PublicState } from './public.reducers';

export const selectPublicState =
  createFeatureSelector<PublicState>(PUBLIC_FEATURE_KEY);

export const loadingPublicShare = createSelector(
  selectPublicState,
  (state) => state.loading,
);

export const selectPublicShareCountingVersion = createSelector(
  selectPublicState,
  (state) => state.countingVersion,
);

export const selectPublicShareError = createSelector(
  selectPublicState,
  (state) => state.error,
);
