import { LoadingStateEnum } from '@dmc-ng/data-access';
import { Action, createReducer, on } from '@ngrx/store';

import { publicActions } from './public.actions';
import { CountingVersionPublicModel } from '../models/share.model';

export const PUBLIC_FEATURE_KEY = 'public';

export interface PublicState {
  countingVersion?: CountingVersionPublicModel;
  loading: LoadingStateEnum;
  error?: unknown;
}

export const initialPublicState: PublicState = {
  loading: LoadingStateEnum.Ready,
};

const reducer = createReducer(
  initialPublicState,
  on(publicActions.getPublicCountingVersion, (state) => ({
    ...state,
    loading: LoadingStateEnum.Loading,
  })),
  on(
    publicActions.publicCountingVersionRetrievedSuccessfully,
    (state, { summary }) => ({
      ...state,
      loading: LoadingStateEnum.Done,
      countingVersion: summary,
    }),
  ),
  on(
    publicActions.publicCountingVersionFailedToBeRetrieved,
    (state, { error }) => ({
      ...state,
      loading: LoadingStateEnum.Done,
      error,
    }),
  ),
);

export function publicReducer(
  state: PublicState | undefined,
  action: Action,
): PublicState {
  return reducer(state, action);
}
