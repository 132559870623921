import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'dmc-console-share-geographical-bloc',
  standalone: true,
  imports: [CommonModule, MatButtonModule, MatIconModule],
  templateUrl: './geographical-bloc.component.html',
  styleUrl: './geographical-bloc.component.scss',
})
export class GeographicalBlocComponent implements OnInit {
  @Input() counter = 0;
  @Input() title = '';
  @Output() showLessItems = new EventEmitter();
  @Output() showMoreItems = new EventEmitter();

  canShowMore = false;
  isAllVisible = true;

  ngOnInit(): void {
    this.isAllVisible = !(this.counter > 5);
    this.canShowMore = this.counter > 5;
  }

  clickShow(): void {
    if (this.isAllVisible) {
      this.showLessItems.emit();
    }
    if (!this.isAllVisible) {
      this.showMoreItems.emit();
    }
    this.isAllVisible = !this.isAllVisible;
  }
}
