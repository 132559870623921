import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router } from '@angular/router';
import {
  publicActions,
  selectPublicShareCountingVersion,
} from '@dmc-ng/data-access/public';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { isNil } from 'lodash';
import { take, map, catchError, of, filter, switchMap } from 'rxjs';

export const shareCountingVersionGuard: CanActivateFn = (
  route: ActivatedRouteSnapshot,
) => {
  const store: Store = inject(Store);
  const router = inject(Router);
  const actions$ = inject(Actions);

  const token = route.paramMap.get('token');

  if (token) {
    store.dispatch(publicActions.getPublicCountingVersion({ token }));
  } else {
    router.navigate(['/404']);
    return of(false);
  }

  const successOrFailure$ = actions$.pipe(
    ofType(
      publicActions.publicCountingVersionRetrievedSuccessfully,
      publicActions.publicCountingVersionFailedToBeRetrieved,
    ),
    take(1),
    switchMap((action) => {
      if (
        action.type ===
        publicActions.publicCountingVersionFailedToBeRetrieved.type
      ) {
        router.navigate(['/404']);
        return of(false);
      } else {
        return store.select(selectPublicShareCountingVersion).pipe(
          filter((countingVersion) => !isNil(countingVersion)),
          take(1),
          map(() => true),
          catchError(() => {
            router.navigate(['/404']);
            return of(false);
          }),
        );
      }
    }),
  );

  return successOrFailure$;
};
